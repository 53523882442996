import Utils from '../Utils'
import Tilt from '../libs/Tilt'
import PaginationLink from './PaginationLink'
import Counter from './Counter'
import {lory} from 'lory.js'
import anime from 'animejs'

class ProjectSlider {
  constructor(el) {
    this.DOM = {}
    this.DOM.el = el

    this.states = {
      isMoving: false,
      currentSlide: 0
    }

    let options = {
      // infinite: 1,
      enableMouseEvents: true,
      slideSpeed: 1000,
      snapBackSpeed: 1000,
      ease: null,
      classNameFrame: 'Slider-frame',
      classNameSlideContainer: 'Slider-items',
      classNamePrevCtrl: 'Slider-prev',
      classNameNextCtrl: 'Slider-next'
    }

    this.slider = lory(this.DOM.el, options)
    this.DOM.slides = this.DOM.el.querySelectorAll('.ProjectSlider-item')
    this.initCounter()
    this.initPagination()
    this.addTypographicTitle(this.states.currentSlide)
    if (!Utils.isTouchDevice() && !Utils.isFirefox()) this.initTilts(this.DOM.slides)

    this.bindEvents()
  }

  getTotalSlides() {
    return this.DOM.el.dataset.totalSlide
  }

  getSlideTitle(index) {
    return this.DOM.slides[index].dataset.projectTitle
  }

  getSlideDataTypographicTitle(index) {
    return JSON.parse(this.DOM.slides[index].dataset.projectTypographicTitle)
  }

  getSlideColors(index) {
    return {
      backgroundColor: this.DOM.slides[index].dataset.projectBackgroundColor,
      color: this.DOM.slides[index].dataset.projectColor
    }
  }

  initTilts(elements) {
    this.tilts = []
    Array.from(elements).map((projectSliderItem) => {
      projectSliderItem.classList.add('Tilt')
      this.tilts.push(
        new Tilt(projectSliderItem, {
          max: 15,
          perspective: 1000,
          transition: false,
          scale: 0.95
        })
      )
    })
  }

  destroyTilts() {
    if (!this.tilts) return
    this.tilts.map((tilt) => {
      tilt.destroy()
    })
  }

  initCounter() {
    let indicatorEl = this.DOM.el.querySelector('.ProjectSlider-counter')
    if (!indicatorEl) {
      throw '.Counter-indicator not found'
      return
    }

    this.counter = new Counter(indicatorEl)

    this.updateCounter(this.states.currentSlide, false)
  }

  updateCounter(newSlide, animated = true) {
    this.counter.setText('indicator', newSlide + 1, animated)
  }

  initPagination() {
    let paginationEl = this.DOM.el.querySelector('.ProjectSlider-pagination')
    if (!paginationEl) {
      throw '.ProjectSlider-pagination not found'
      return
    }

    this.pagination = {
      prev: new PaginationLink(paginationEl.querySelector('.ProjectSlider-prev')),
      next: new PaginationLink(paginationEl.querySelector('.ProjectSlider-next'))
    }

    this.pagination.prev.disable()
    this.pagination.next.setText(this.getSlideTitle(1), false)
    this.pagination.next.enable()
  }

  updatePagination(newSlide, animated = true) {
    const totalSlides = this.getTotalSlides()
    let delay = 300

    if (newSlide > 0) {
      this.pagination.prev.enable()
      setTimeout(() => this.pagination.prev.setText(this.getSlideTitle(newSlide - 1), animated), delay)
    } else {
      this.pagination.prev.disable()
    }

    if (newSlide < totalSlides - 1) {
      this.pagination.next.enable()
      setTimeout(() => this.pagination.next.setText(this.getSlideTitle(newSlide + 1), animated), delay)
    } else {
      this.pagination.next.disable()
    }
  }

  destroyPagination() {
    this.pagination.prev.destroy()
    this.pagination.next.destroy()
  }

  addTypographicTitle(newSlide) {
    const imageData = this.getSlideDataTypographicTitle(newSlide)
    if (!imageData) return

    this.DOM.typographicTitle = this.createTypographicTitleImage(imageData.width, imageData.height)

    let src = imageData.src

    if (Utils.isRetina() && imageData.srcset) {
      let temp, srcset = {}
      imageData.srcset.split(',').map((src) => {
        temp = src.split(' ')
        srcset[temp[1]] = temp[0]
      })
      src = srcset['2x']
    }

    Utils.loadImage(src, (event) => {
      if (newSlide !== this.states.currentSlide) return

      let params = {
        targets: this.DOM.typographicTitle,
        opacity: [0, 1],
        translateX: {
          value: [window.innerWidth / 10 * (this.states.direction ? 1 : -1), 0],
          easing: 'easeOutQuint',
          duration: 3000
        },
        translateZ: 0,
        duration: 250,
        easing: 'easeInQuint',
        begin: () => {
          this.DOM.typographicTitle.src = src
          this.DOM.el.appendChild(this.DOM.typographicTitle)
        },
        complete: (event) => {
          if (event.target) event.target.style.transform = null
        }
      }

      if (window.innerWidth < mediaQueries.tablet) params.rotate = {value: -90, duration: 1}
      anime(params)
    })
  }

  removeTypographicTitle() {
    if (!this.DOM.typographicTitle) return
    let oldImage = this.DOM.typographicTitle
    this.DOM.typographicTitle = null
    anime({
      targets: oldImage,
      opacity: 0,
      duration: 200,
      easing: 'easeInQuint',
      complete: () => oldImage.remove()
    })
  }

  createTypographicTitleImage(width, height) {
    let image = document.createElement('img')
    image.classList.add('ProjectSlider-typographicTitle')
    image.classList.add('TypographicTitle')
    image.style.opacity = 0
    if (width && height) {
      image.width = width
      image.height = height
    }
    return image
  }

  updateBackgroundNoise(newSlide, animated = true) {
    const colors = this.getSlideColors(newSlide)
    if (colors && window.app.backgroundNoise) {
      window.app.backgroundNoise.setColors(colors.backgroundColor, colors.color, animated)
    }
  }

  handleEvent(event) {
    console.log(event)
  }

  bindEvents() {
    this.boundEvents = {
      onKeyDown: this.onKeyDown.bind(this),
      onTouchStart: this.onTouchStart.bind(this),
      onTouchMove: this.onTouchMove.bind(this),
      onTouchEnd: this.onTouchEnd.bind(this),
      beforeSlide: this.beforeSlide.bind(this),
      afterSlide: this.afterSlide.bind(this),
      onResize: this.onResize.bind(this),
      beforeDestroy: this.beforeDestroy.bind(this),
    }

    document.addEventListener('keydown', this.boundEvents.onKeyDown)
    this.DOM.el.addEventListener('on.lory.touchstart', this.boundEvents.onTouchStart)
    this.DOM.el.addEventListener('on.lory.touchmove', this.boundEvents.onTouchMove)
    this.DOM.el.addEventListener('on.lory.touchend', this.boundEvents.onTouchEnd)
    this.DOM.el.addEventListener('before.lory.slide', this.boundEvents.beforeSlide)
    this.DOM.el.addEventListener('after.lory.slide', this.boundEvents.afterSlide)
    this.DOM.el.addEventListener('on.lory.resize', this.boundEvents.onResize)
    this.DOM.el.addEventListener('before.lory.destroy', this.boundEvents.beforeDestroy)
  }

  unbindEvents() {
    document.removeEventListener('keydown', this.boundEvents.onKeyDown)
    this.DOM.el.removeEventListener('on.lory.touchstart', this.boundEvents.onTouchStart)
    this.DOM.el.removeEventListener('on.lory.touchmove', this.boundEvents.onTouchMove)
    this.DOM.el.removeEventListener('on.lory.touchend', this.boundEvents.onTouchEnd)
    this.DOM.el.removeEventListener('before.lory.slide', this.boundEvents.beforeSlide)
    this.DOM.el.removeEventListener('after.lory.slide', this.boundEvents.afterSlide)
    this.DOM.el.removeEventListener('on.lory.resize', this.boundEvents.onResize)
    this.DOM.el.removeEventListener('before.lory.destroy', this.boundEvents.beforeDestroy)

    delete this.boundEvents
  }

  onKeyDown(event) {
    const keyCode = event.keyCode || event.which
    switch (keyCode) {
      case 37: // left key
        if (this.states.currentSlide == 0) return
        this.slider.prev()
        break
      case 39: // right key
        if (this.states.currentSlide == this.getTotalSlides() - 1) return
        this.slider.next()
        break
    }
  }

  onTouchStart(event) {
  }

  onTouchMove(event) {
    if (!this.states.isMoving) {
      this.states.isMoving = true
      event.target.classList.add('is-moving')
    }
  }

  onTouchEnd(event) {
    this.states.isMoving = false
    event.target.classList.remove('is-moving')
  }

  onResize(event) {
    if (this.states.currentSlide !== 0) {
      this.reset()
    }
  }

  beforeSlide(event) {
    this.removeTypographicTitle()
  }

  afterSlide(event) {
    const newSlide = event.detail.currentSlide
    this.states.direction = this.states.currentSlide >= newSlide
    this.updateBackgroundNoise(newSlide)
    this.updateCounter(newSlide)
    this.updatePagination(newSlide)
    this.addTypographicTitle(newSlide)
    this.states.currentSlide = newSlide
  }

  beforeDestroy(event) {
    this.unbindEvents()
  }

  reset() {
    this.slider.reset()
    this.states.currentSlide = 0
    this.updateCounter(0)
    this.updatePagination(0)
    this.updateBackgroundNoise(0)
    this.removeTypographicTitle()
    this.addTypographicTitle(0)
  }

  destroy() {
    this.slider.destroy()
    this.destroyPagination()
    if (!Utils.isTouchDevice()) this.destroyTilts()
  }
}

export default ProjectSlider
