/**
 Inspired by https://github.com/micku7zu/vanilla-tilt.js
 **/

class Tilt {

  constructor(element, settings = {}) {
    if (!(element instanceof Node)) {
      throw ('Can\'t initialize VanillaTilt because ' + element + ' is not a Node.')
    }

    this.width = null
    this.height = null
    this.left = null
    this.top = null
    this.transitionTimeout = null
    this.updateCall = null

    this.updateBound = this.update.bind(this)
    this.resetBound = this.reset.bind(this)

    this.element = element
    this.settings = this.extendSettings(settings)

    this.reverse = this.settings.reverse ? -1 : 1

    this.glare = this.isSettingTrue(this.settings.glare)
    this.glarePrerender = this.isSettingTrue(this.settings['glare-prerender'])

    if (this.glare) {
      this.prepareGlare()
    }

    this.addEventListeners()
  }

  isSettingTrue(setting) {
    return setting === '' || setting === true || setting === 1
  }

  addEventListeners() {
    this.onMouseEnterBound = this.onMouseEnter.bind(this)
    this.onMouseMoveBound = this.onMouseMove.bind(this)
    this.onMouseLeaveBound = this.onMouseLeave.bind(this)
    this.onWindowResizeBound = this.onWindowResize.bind(this)

    this.element.addEventListener('mouseenter', this.onMouseEnterBound)
    this.element.addEventListener('mousemove', this.onMouseMoveBound)
    this.element.addEventListener('mouseleave', this.onMouseLeaveBound)
    if (this.glare) {
      window.addEventListener('resize', this.onWindowResizeBound)
    }
  }

  removeEventListeners() {
    this.element.removeEventListener('mouseenter', this.onMouseEnterBound)
    this.element.removeEventListener('mousemove', this.onMouseMoveBound)
    this.element.removeEventListener('mouseleave', this.onMouseLeaveBound)
    if (this.glare) {
      window.removeEventListener('resize', this.onWindowResizeBound)
    }
  }

  destroy() {
    clearTimeout(this.transitionTimeout)
    if (this.updateCall !== null) {
      cancelAnimationFrame(this.updateCall)
    }

    this.reset()

    this.removeEventListeners()
    this.element.tilt = null
    delete this.element.tilt

    this.element = null
  }

  onMouseEnter(event) {
    this.updateElementPosition()
    this.element.style.willChange = 'transform'

    if (this.settings.transition) {
      this.setTransition()
    }
  }

  onMouseMove(event) {
    if (this.updateCall !== null) {
      cancelAnimationFrame(this.updateCall)
    }

    this.event = event
    this.updateCall = requestAnimationFrame(this.updateBound)
  }

  onMouseLeave(event) {
    if (this.settings.transition) {
      this.setTransition()
    }

    if (this.settings.reset) {
      requestAnimationFrame(this.resetBound)
    }
  }

  reset() {
    this.event = {
      pageX: this.left + this.width / 2,
      pageY: this.top + this.height / 2
    }

    this.element.style.transform = 'perspective(0px) rotateX(0deg) rotateY(0deg) scale3d(1, 1, 1)'

    if (this.glare) {
      this.glareElement.style.transform = 'rotate(180deg) translate(-50%, -50%)'
      this.glareElement.style.opacity = '0'
    }
  }

  getValues() {
    let x = (this.event.clientX - this.left) / this.width
    let y = (this.event.clientY - this.top) / this.height

    x = Math.min(Math.max(x, 0), 1)
    y = Math.min(Math.max(y, 0), 1)

    let tiltX = (this.reverse * (this.settings.max / 2 - x * this.settings.max)).toFixed(2)
    let tiltY = (this.reverse * (y * this.settings.max - this.settings.max / 2)).toFixed(2)
    let angle = Math.atan2(this.event.clientX - (this.left + this.width / 2), -(this.event.clientY - (this.top + this.height / 2))) * (180 / Math.PI)

    return {
      tiltX: tiltX,
      tiltY: tiltY,
      percentageX: x * 100,
      percentageY: y * 100,
      angle: angle
    }
  }

  updateElementPosition() {
    let rect = this.element.getBoundingClientRect()

    this.width = this.element.offsetWidth
    this.height = this.element.offsetHeight
    this.left = rect.left
    this.top = rect.top
  }

  update() {
    let values = this.getValues()

    this.element.style.transform = 'perspective(' + this.settings.perspective + 'px) ' +
      'rotateX(' + (this.settings.axis === 'x' ? 0 : values.tiltY) + 'deg) ' +
      'rotateY(' + (this.settings.axis === 'y' ? 0 : values.tiltX) + 'deg) ' +
      'scale3d(' + this.settings.scale + ', ' + this.settings.scale + ', ' + this.settings.scale + ')'

    if (this.glare) {
      this.glareElement.style.transform = `rotate(${values.angle}deg) translate(-50%, -50%)`
      this.glareElement.style.opacity = `${values.percentageY * this.settings['max-glare'] / 100}`
    }

    this.element.dispatchEvent(new CustomEvent('tiltChange', {
      'detail': values
    }))

    this.updateCall = null
  }

  /**
   * Appends the glare element (if glarePrerender equals false)
   * and sets the default style
   */
  prepareGlare() {
    // If option pre-render is enabled we assume all html/css is present for an optimal glare effect.
    if (!this.glarePrerender) {
      // Create glare element
      const jsTiltGlare = document.createElement('div')
      jsTiltGlare.classList.add('js-tilt-glare')

      const jsTiltGlareInner = document.createElement('div')
      jsTiltGlareInner.classList.add('js-tilt-glare-inner')

      jsTiltGlare.appendChild(jsTiltGlareInner)
      this.element.appendChild(jsTiltGlare)
    }

    this.glareElementWrapper = this.element.querySelector('.js-tilt-glare')
    this.glareElement = this.element.querySelector('.js-tilt-glare-inner')

    if (this.glarePrerender) {
      return
    }

    Object.assign(this.glareElementWrapper.style, {
      'position': 'absolute',
      'top': '0',
      'left': '0',
      'width': '100%',
      'height': '100%',
      'overflow': 'hidden'
    })

    Object.assign(this.glareElement.style, {
      'position': 'absolute',
      'top': '50%',
      'left': '50%',
      'pointer-events': 'none',
      'background-image': `linear-gradient(0deg, rgba(255,255,255,0) 0%, rgba(255,255,255,1) 100%)`,
      'width': `${this.element.offsetWidth * 2}px`,
      'height': `${this.element.offsetWidth * 2}px`,
      'transform': 'rotate(180deg) translate(-50%, -50%)',
      'transform-origin': '0% 0%',
      'opacity': '0',
    })
  }

  updateGlareSize() {
    Object.assign(this.glareElement.style, {
      'width': `${this.element.offsetWidth * 2}`,
      'height': `${this.element.offsetWidth * 2}`,
    })
  }

  onWindowResize() {
    this.updateGlareSize()
  }

  setTransition() {
    clearTimeout(this.transitionTimeout)
    this.element.style.transition = this.settings.speed + 'ms ' + this.settings.easing
    if (this.glare) this.glareElement.style.transition = `opacity ${this.settings.speed}ms ${this.settings.easing}`

    this.transitionTimeout = setTimeout(() => {
      this.element.style.transition = ''
      if (this.glare) {
        this.glareElement.style.transition = ''
      }
    }, this.settings.speed)

  }

  extendSettings(settings) {
    let defaultSettings = {
      reverse: false,
      max: 35,
      perspective: 1000,
      easing: 'cubic-bezier(.03,.98,.52,.99)',
      scale: '1',
      speed: '300',
      transition: true,
      axis: null,
      glare: false,
      'max-glare': 1,
      'glare-prerender': false,
      reset: true
    }

    let newSettings = {}
    for (var property in defaultSettings) {
      if (property in settings) {
        newSettings[property] = settings[property]
      } else if (this.element.hasAttribute('data-tilt-' + property)) {
        let attribute = this.element.getAttribute('data-tilt-' + property)
        try {
          newSettings[property] = JSON.parse(attribute)
        } catch (e) {
          newSettings[property] = attribute
        }

      } else {
        newSettings[property] = defaultSettings[property]
      }
    }

    return newSettings
  }
}

export default Tilt
